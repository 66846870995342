<script setup lang="ts">
import { useMenuStete } from './menu-context'
import CustomSubMenu from './custom-sub-menu.vue'

const { theme, menuData, selectedKeys, openKeys, handleOpenKeys, handleSelectedKeys, handleMenuSelect } = useMenuStete()

const menuTheme = computed(() => {
  if (theme.value === 'inverted')
    return 'dark'
  return theme.value
})
</script>

<template>
  <a-menu
    :theme="menuTheme"
    :selected-keys="selectedKeys"
    :open-keys="openKeys"
    :collapsed="true"
    mode="inline"
    @update:open-keys="handleOpenKeys"
    @update:selected-keys="handleSelectedKeys"
    @select="handleMenuSelect"
  >
    <template v-for="item in menuData" :key="item.id">
      <CustomSubMenu :item="item" />
    </template>
  </a-menu>
</template>
