import { arrayType, eventType, runEvent, stringType } from '@v-c/utils'
import type { ExtractPropTypes, VNodeChild } from 'vue'
import type { SelectEventHandler } from 'ant-design-vue/es/menu/src/interface'
import type { Key, MenuSelectEvent, ThemeType } from '@/layout/basic-layout/typing'

export type CustomMenuData = CustomMenuDataItem[]

export interface CustomMenuDataItem {
  id: string | number
  value: number | string
  userId?: number
  containerMax?: number
  type: string
  title: string | (() => VNodeChild)
  icon?: string | (() => VNodeChild)
  children?: CustomMenuDataItem[]
}

export const menuProps = {
  theme: stringType<ThemeType>('light'),
  openKeys: arrayType<(number | string)[]>(),
  selectedKeys: arrayType<(number | string)[]>(),
  menuData: arrayType<CustomMenuData>(),
  'onUpdate:openKeys': eventType<(val: CustomMenuDataItem[]) => void>(),
  'onUpdate:selectedKeys': eventType<(val: CustomMenuDataItem[]) => void>(),
  'onMenuSelect': eventType<(data: MenuSelectEvent) => void>(),
}

type MenuProps = Partial<ExtractPropTypes<typeof menuProps>>

function useMenuStore(props: MenuProps) {
  const theme = computed(() => props.theme)
  const menuData = computed(() => props.menuData)
  const selectedKeys = computed(() => props.selectedKeys)
  const openKeys = computed(() => props.openKeys)
  const menuDataMap = ref(new Map<Key, CustomMenuDataItem>())

  watch(menuData, () => {
    menuDataMap.value.clear()
    menuDataMap.value = traverseTrees(menuData.value ?? [])
  }, { immediate: true })

  const handleOpenKeys = (val: Key[]) => {
    const res = val.map(item => menuDataMap.value.get(item))
    runEvent(props['onUpdate:openKeys'], res)
  }

  const handleSelectedKeys = (val: Key[]) => {
    const res = val.map(item => menuDataMap.value.get(item))
    runEvent(props['onUpdate:selectedKeys'], res)
  }

  // 平铺树到map中
  function traverseTrees(trees: CustomMenuData) {
    const map = new Map()

    trees.forEach(node => traverseTreeNode(node))

    function traverseTreeNode(node: CustomMenuDataItem) {
      if (node == null)
        return

      map.set(node.id, node)

      if (node.children)
        node.children.forEach(chil => traverseTreeNode(chil))
    }

    return map
  }

  const handleMenuSelect: SelectEventHandler = (data) => {
    runEvent(props.onMenuSelect, data)
  }

  return { theme, menuData, selectedKeys, openKeys, handleOpenKeys, handleSelectedKeys, handleMenuSelect }
}

const [useMenuProvider, useMenuInject] = createInjectionState(useMenuStore)

export { useMenuProvider }

export const useMenuStete = (): ReturnType<typeof useMenuStore> => useMenuInject()!
