<script setup lang="ts">
import type { VNodeChild } from 'vue'
import { isFunction } from '@v-c/utils'
import type { CustomMenuDataItem } from './menu-context'
import AsyncIcon from '@/layout/components/menu/async-icon.vue'

withDefaults(defineProps<{ item: CustomMenuDataItem }>(), {})

function renderTitle(title: VNodeChild | (() => VNodeChild)) {
  if (isFunction(title))
    return title()

  return title
}
</script>

<template>
  <template v-if="item.children && item.children.length > 0">
    <a-sub-menu :key="item.id">
      <template v-if="item.icon" #icon>
        <AsyncIcon :icon="item.icon" />
      </template>
      <template #title>
        {{ renderTitle(item.title) }}
      </template>
      <template v-for="menu in item.children">
        <template v-if="menu.children && menu.children.length > 0">
          <custom-sub-menu :key="menu.id" :item="menu" />
        </template>
        <template v-else>
          <a-menu-item :key="menu.id">
            <template v-if="menu.icon" #icon>
              <AsyncIcon :icon="menu.icon" />
            </template>

            <template v-if="menu.title">
              {{ renderTitle(menu.title) }}
            </template>
          </a-menu-item>
        </template>
      </template>
    </a-sub-menu>
  </template>

  <template v-else>
    <a-menu-item :key="item.id">
      <template v-if="item.icon" #icon>
        <AsyncIcon :icon="item.icon" />
      </template>
      <template v-if="item.title">
        {{ renderTitle(item.title) }}
      </template>
    </a-menu-item>
  </template>
</template>
