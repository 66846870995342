<script setup lang="ts">
import { menuProps, useMenuProvider } from './menu-context'
import CustomMenu from './custom-menu.vue'

const props = defineProps(menuProps)
useMenuProvider(props)
</script>

<template>
  <div h-full flex>
    <div w-300px class="of-x-hidden of-y-auto scrollbar" border-solid border-0 border-r-1 style="background: var(--bg-color);border-color: rgb(230, 230, 230);">
      <CustomMenu />
    </div>
    <div flex-1 flex-col flex>
      <slot name="operation" />
      <div flex-1 flex overflow-hidden>
        <slot />
      </div>
    </div>
  </div>
</template>
